:root {
  --app-height: 100vh;
}

li {
  list-style: none;
}

ul {
  margin: 0;
  padding: 0;
}

.ant-picker-cell {
  cursor: default !important;
}

.ant-card-meta-title {
  white-space: initial !important;
}

.ant-card-head {
  padding: 0 12px !important;
}
